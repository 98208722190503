import { Icon } from '@chakra-ui/react';
import { MdBarChart, MdPerson, MdHome, MdLock, MdOutlineShoppingCart } from 'react-icons/md';

// Admin Imports
import MainDashboard from 'views/admin/default';
import NFTMarketplace from 'views/admin/marketplace';
import Profile from 'views/admin/profile';
import DataTables from 'views/admin/dataTables';
import RTL from 'views/admin/rtl';

// Auth Imports
import SignInCentered from 'views/auth/signIn';
import {SlEnvolopeLetter} from "react-icons/sl";
import {AiTwotoneMail} from "react-icons/ai";
import { RiAdminLine } from 'react-icons/ri';
import {BsFillInfoSquareFill} from "react-icons/bs";

const routes = [
	{
		name: 'Home',
		layout: '/admin',
		path: '/default',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		component: MainDashboard
	},
	{
		name: 'About',
		layout: '/admin',
		icon: <Icon as={BsFillInfoSquareFill} width='20px' height='20px' color='inherit' />,
		path: '/about',
		component: DataTables
	},
	{
		name: 'Membership',
		layout: '/admin',
		path: '/profile',
		icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
		component: Profile
	},
	{
		name: 'Newsletter',
		layout: '/admin',
		path: '/profile',
		icon: <Icon as={AiTwotoneMail} width='20px' height='20px' color='inherit' />,
		component: Profile
	},
	{
		name: 'Shop',
		layout: '/admin',
		path: '/nft-marketplace',
		icon: <Icon as={MdOutlineShoppingCart} width='20px' height='20px' color='inherit' />,
		component: NFTMarketplace,
		secondary: true
	},
	{
		name: 'Site Manager',
		layout: '/rtl',
		path: '/rtl-default',
		icon: <Icon as={RiAdminLine} width='20px' height='20px' color='inherit' />,
		component: RTL
	}
];

export default routes;
