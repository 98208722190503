/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {Box, Icon, SimpleGrid, useColorModeValue} from '@chakra-ui/react';
// Assets
// Custom components
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import {MdAttachMoney} from 'react-icons/md';
import {AiTwotoneCalendar} from "react-icons/ai";

export default function UserReports() {
  // Chakra Color Mode
  const brandColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  return (
      <Box pt={{base: '130px', md: '80px', xl: '80px'}}>
        <SimpleGrid columns={{base: 1, md: 2, lg: 3, '2xl': 6}} gap='20px' mb='20px'>
          <MiniStatistics
              startContent={
                <IconBox
                    w='56px'
                    h='56px'
                    bg={boxBg}
                    icon={<Icon w='32px' h='32px' as={AiTwotoneCalendar} color={brandColor}/>}
                />
              }
              name='Regular meeting'
              value='November 29th 2023'
          />
          <MiniStatistics
              startContent={
                <IconBox
                    w='56px'
                    h='56px'
                    bg={boxBg}
                    icon={<Icon w='32px' h='32px' as={AiTwotoneCalendar} color={brandColor}/>}
                />
              }
              name='Board Meeting'
              value='December 4th 2023'
          />
        </SimpleGrid>

        {/*<SimpleGrid columns={{base: 1, md: 2, xl: 2}} gap='20px' mb='20px'>*/}
        {/*  <TotalSpent/>*/}
        {/*  <WeeklyRevenue/>*/}
        {/*</SimpleGrid>*/}
        {/*<SimpleGrid columns={{base: 1, md: 1, xl: 2}} gap='20px' mb='20px'>*/}
        {/*  <CheckTable tableData={tableDataCheck}/>*/}
        {/*  <SimpleGrid columns={{base: 1, md: 2, xl: 2}} gap='20px'>*/}
        {/*    <DailyTraffic/>*/}
        {/*    <PieCard/>*/}
        {/*  </SimpleGrid>*/}
        {/*</SimpleGrid>*/}
        {/*<SimpleGrid columns={{base: 1, md: 1, xl: 2}} gap='20px' mb='20px'>*/}
        {/*  <ComplexTable tableData={tableDataComplex}/>*/}
        {/*  <SimpleGrid columns={{base: 1, md: 2, xl: 2}} gap='20px'>*/}
        {/*    <Tasks/>*/}
        {/*    <MiniCalendar h='100%' minW='100%' selectRange={false}/>*/}
        {/*  </SimpleGrid>*/}
        {/*</SimpleGrid>*/}
      </Box>
  );
}
